<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-11-04 15:22:46
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 15:17:25
 * @Description: 
-->
<template>
	<div class="container">
		<a-form-model ref="form" :model="form" :label-col="{span: 2}" :wrapper-col="{span: 16}" :rules="rules">
			<a-form-model-item label="菜单图片" prop="images" ref="img">
				<img-uploader v-model="form.images" model-type="Arr" :max="999" :showUploadList="false" :multiple="true" @success="$refs.img.onFieldBlur()" @remove="$refs.img.onFieldBlur()" />
			</a-form-model-item>
			<a-form-model-item :wrapper-col="{span: 2, offset: 22}">
				<a-button block type="primary" size="large" @click="showModal">保存</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import {Modal} from 'ant-design-vue'
import ImgUploader from '@/components/imgUploader'

export default {
	name: 'ElectronMenu',
	components: {ImgUploader},
	data() {
		return {
			form: {
				images: []
			},
			rules: {
				images: {
					trigger: ['change', 'blur'],
					required: true,
					type: 'array',
					message: '请上传菜单照片'
				}
			}
		}
	},
	methods: {
		/**
		 * @description: 保存弹窗
		 * @return {*}
		 */
		showModal() {
			Modal.confirm({
				title: '提示',
				content: '确认保存吗？',
				onOk: async () => {}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	padding: 25px 20px 1px 50px;
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
}
</style>
